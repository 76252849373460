// import jwtDecode from "jwt-decode";
import { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "../utils/setDocumentTitle";
import AuthUser from './AuthUser';
import MicrosoftLogin from "react-microsoft-login";
import {Loader, isWebview} from "../utils/Helpers";
import useFetch from "../hooks/useFetch";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import LeftServices from "./common/LeftServices";
import RightServices from "./common/RightServices";
import Footer from '../navbar/footer';

// import { GoogleLogin, GoogleOAuthenProvider} from '@react-oauth/google';
    import jwtDecode from 'jwt-decode';

// const google = window.google;

export default function Login() {
    useDocumentTitle(`Login`);
    // document.body.className = "hide-header hide-footer";
    const {http,setToken} = AuthUser();
    const [email,setEmail] = useState();
    const [password,setPassword] = useState();
    const alert = useAlert();
    const [submitProcess, setSubmitProcess] = useState(false);
    // const google = window.google;

    const shikaWindow = useRef(null);
    const [shikaAuthToken, setShikaAuthToken] = useState("");

    // const [loading, setLoading] = useState(false);

    const { handleGoogle, loading, setLoading, error } = useFetch(
        "https://shikapps.com"
    );


    // const [msalInstance, onMsalInstanceChange] = useState();

//   const loginHandlerMs = (err, data, msal) => {
//     console.log(err, data);
//     // some actions
//     if (!err && data) {
//       onMsalInstanceChange(msal);
//     }
//   };


    const submitForm = (e) =>{
        e.preventDefault();
        // api call
        setSubmitProcess(true);
        http.post('customer-login',{email:email,password:password}).then((res)=>{
            if ( res.data.status==='success' ) {
                setToken(res.data.data.user, res.data.data.token);
            } else {
                alert.show(res.data.message);
            }
            setSubmitProcess(false);
        })
    }

    //const [msalInstance, onMsalInstanceChange] = useState();
    const handleMicrosoftLogin = (err, data, msal) => {
        // console.log(err, data);
        // some actions
        if (!err && data) {
            //onMsalInstanceChange(msal); // login storage in frontend
            try {
                setLoading(true);
                http.post('microsoft-login', data).then((res)=>{
                    if ( res.data.status==='success' ) {
                        setToken(res.data.data.user, res.data.data.token);
                    } else {
                        alert.show(res.data.message);
                    }
                    setLoading(false);
                })
            } catch (error) {
                alert.error(error);
            }
        }
    };
    //const logoutHandler = () => {
        //msalInstance.logout();
   // };

    const handleShikaLoginWindow = () => {
        shikaWindow.current = window.open(
            "https://shika365.com/account/oAuthShika.php?clientId=shikaoAuth2023AppGeneral&redirectTo="+process.env.REACT_APP_URL,
            "Shika365 Authentication",
            "width=400,height=600,top:100,left:100"
        )

        // var timer = setInterval( () => { 
        //     if(shikaWindow.current.closed) {
        //         clearInterval(timer);
        //         handleShikaLogin(shikaAuthToken);
        //     }
        // }, 1000);       
        
    }
    const handleShikaLogin = (authToken) => {
        // console.log('called handleShikaLogin',authToken);
        try {
            setLoading(true);
            http.post('shika365-login', {auth_token: authToken}).then((res)=>{
                if ( res.data.status==='success' ) {
                    setToken(res.data.data.user, res.data.data.token);
                } else {
                    alert.show(res.data.message);
                }
                setLoading(false);
            })
        } catch (error) {
            alert.error(error);
        }
    }


    useEffect( () => {
        
    //     const navigator = window.navigator
    // const userAgent = navigator.userAgent
    // const normalizedUserAgent = userAgent.toLowerCase()
    // alert.show( isWebview==true?1:0 );
        // const handleGoogleLoginResponse = (response) =>{
        //     // console.log(response);
        //     let googleData = jwtDecode(response.credential);
        //     setLoading(true);
        //     http.post('google-login',googleData).then((res)=>{
        //         if ( res.data.status==='success' ) {
        //             setToken(res.data.data.user, res.data.data.token);
        //         } else {
        //             alert.show(res.data.message);
        //         }
        //         setLoading(false);
        //     })
        // }
        // console.log('google config', google);
        // if (google) {
        //     // console.log('google config done');
        //     // Google Config
        //     google?.accounts?.id?.initialize({
        //         client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        //         callback: handleGoogle
        //     });
        //     // Google Button
        //     google?.accounts?.id.renderButton(
        //         document.getElementById("GoogleSignInDiv"),
        //         {
        //             theme: "outline",
        //             size: "large",
        //             width: 300
        //         }
        //     );
        //     // google?.accounts?.id.prompt();
        // } else {
        //     // console.log('google not found yet')
        //     setTimeout ( ()=> {
        //         // console.log('google config again');
        //             // Google Config
        //         google?.accounts?.id?.initialize({
        //             client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        //             callback: handleGoogle
        //         });
        //         // Google Button
        //         google?.accounts?.id.renderButton(
        //             document.getElementById("GoogleSignInDiv"),
        //             {
        //                 theme: "outline",
        //                 size: "large",
        //                 width: 300
        //             }
        //         );
        //     }, 8000);
        // }


        // shika login window to get response
        const shikaChildResponse = (event) => {
            // console.log(event.data)
            if ( event?.data && event.data!=undefined && typeof event.data === 'string' ) {
                console.log('calling handleShikaLogin '+event.data)
                setShikaAuthToken(event.data);
                handleShikaLogin(event.data);
            }
        }
        window.addEventListener("message", shikaChildResponse);

        // console.log(isWebview());

        // Return cleanup function
        return () => {
            window.removeEventListener("message", shikaChildResponse);
        }

    }, []);


    const handleGoogle2 = async (response) => {
        let googleData = jwtDecode(response.credential);
        setLoading(true);
        http.post('google-login',googleData).then((res)=>{
            if ( res.data.status==='success' ) {
                setToken(res.data.data.user, res.data.data.token);
            } else {
                alert.show(res.data.message);
            }
            setLoading(false);
        })
        };
    


    return(
        <div className="row justify-content-center0 pt-5 login-form-container">
            { loading? Loader('Please wait ...') : null }
   
            <div className="col-sm-12 col-md-6 login-form-left">

                <div className="left-toppart">
                <LeftServices />  
                <RightServices />  
                </div>
                <div className="left-bottompart">      
                <hr />
                <Footer />
                </div>
            </div>
            <div className="col-sm-12 col-md-6 login-form-body">
                <div className="card p-4">
                    <img className="login-logo" src="/logo.png" title="Shika365" alt="Shika365" />
                    <h1 className="text-center mb-3">Login </h1>
                    <form onSubmit={submitForm} autoComplete="off">
                    <div className="form-group">
                        <label>Email address:</label>
                        <input type="email" className="form-control" placeholder="Enter email"
                            onChange={e=>setEmail(e.target.value)}
                        id="email" required/>
                    </div>
                    <div className="form-group mt-3">
                        <label>Password:</label>
                        <input type="password" className="form-control" placeholder="Enter password"
                            onChange={e => setPassword(e.target.value)}
                        id="pwd" required />
                    </div>
                    <div className="form-group mt-3 text-end">
                        <Link to="/forgot-password">Forgot Password?</Link>
                    </div>

                    <div className="text-center form-group mt-3">
                        {
                            submitProcess 
                            ? 
                            <button type="button" className="btn btn-primary mt-4">Please wait...</button> 
                            : 
                            <button type="submit" className="btn btn-primary mt-4">Login</button>
                        }
                    
                    </div>
                    </form>
                    <div className="form-group mt-3 text-center">
                        Do not have account? <Link to="/register">Register</Link>
                    </div>

                    { isWebview() == false ? 
                    
                    <div><hr />
                    <h4 className="text-center">Or Login with</h4>
                    <div className="social-btn-container">
                        <div>
                            
                            {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                            <GoogleLogin
                            width="300"
                            onSuccess={handleGoogle}
                            />
                            </GoogleOAuthProvider> */}

                            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                                <GoogleLogin
                                    width={300}
                                    onSuccess={handleGoogle2}
                                />
                            </GoogleOAuthProvider>
                            
                            <br />

                            {/* <div  id="GoogleSignInDiv"></div> <br /> */}
                            <MicrosoftLogin  clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID} graphScopes={["user.read"]} buttonTheme="light" authCallback={handleMicrosoftLogin} >
                                <button className="social-btn">
                                    <img src="/microsoft.png" alt="" /> <span className="text">Sign in with Microsoft</span>
                                </button>
                            </MicrosoftLogin>
                            
                             <br />
                             <button onClick={handleShikaLoginWindow} className="social-btn">
                                <img src="/logo.png" alt="" /> <span className="text">Sign in with Shika365</span>
                            </button>
                        </div>
                    </div>
                    </div> : null }
                </div>
            </div>
            
        </div>
    )
}